export const ScanConstants = {
    WEBSITE_STATUS: {
        notFound: "not-found",
        exactFound: "exact-found",
        invalidFound: "invalid-found",
    },
    SCAN_STATUS: {
        pending: "pending",
        completed: "completed",
        failed: "failed",
    },
    SERVICES: {
        listing: "listing",
        voice: "voice",
        review: "review",
        social: "social"
    }
};

export const JobConstants = {
    pending: "pending",
    partiallyCompleted: "partiallyCompleted",
    completed: "completed",
    failed: "failed"
};

export const GOOGLE_MAP_API_KEY = "AIzaSyAvMGmHLuzC6Q2gR16oFuevYREnUQDmxJs";
