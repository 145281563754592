import { useNavigate } from "react-router-dom";
import ScanResults from "../../ScanResults";
import Step1 from "./Step1";
import Step2 from "./Step2";

const SynupTemplate = (props) => {

    const navigate = useNavigate();
    const { vendor, activeTab, formFields, handleStep1, handleStep2, createAndDownloadPdf, reportId } = props;

    return (
        <>
            <div className='logo d-flex justify-content-center '>
                <img onClick={(e) => navigate('/home')} src={vendor?.logo} />
            </div>

            <div className='tabs_section scanner'>
                <ul className="nav scanner-stepper justify-content-center align-items-center nav-pills" id="pills-tab" role="tablist">

                    <li className={`nav-item step d-flex flex-row align-items-center ${activeTab > 1 ? 'completed' : ''} ${activeTab === 1 ? 'active' : ''}`} role="presentation">
                        <button className={`nav-link nocursorhover nav_size rounded-5`} id="pills-home-tab" type="button" role="tab" aria-controls="pills-home" aria-selected="true">1</button>
                        <span className='actv'>Nature of Business</span>
                    </li>

                    <li className={`line bar nav-item ${activeTab > 2 ? 'completed' : ''} ${activeTab === 2 ? 'active' : ''}`}></li>

                    <li className={`nav-item step d-flex flex-row align-items-center ${activeTab > 2 ? 'completed' : ''} ${activeTab === 2 ? 'active' : ''}`} role="presentation">
                        <button className={`nav-link nocursorhover nav_size  rounded-5`} id="pills-profile-tab" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">2</button>
                        <span className='actv'>Business Details</span>
                    </li>

                    <li className={`line bar nav-item ${activeTab > 3 ? 'completed' : ''} ${activeTab === 3 ? 'active' : ''}`}></li>

                    <li className={`nav-item step d-flex flex-row align-items-center ${activeTab > 3 ? 'completed' : ''} ${activeTab === 3 ? 'active' : ''}`} role="presentation">
                        <button className={`nav-link nocursorhover nav_size rounded-5`} id="pills-contact-tab" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">3</button>
                        <span className='actv'>Scan Results</span>
                    </li>

                </ul>
                <div className="tab-content " id="pills-tabContent">
                    <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <Step1 vendor={vendor} formFields={formFields} onSubmit={handleStep1} />
                    </div>
                    <div className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <Step2 vendor={vendor} formFields={formFields} onSubmit={handleStep2} />
                    </div>
                    <div className={`tab-pane fade ${activeTab === 3 ? 'show active' : ''}`} id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <ScanResults vendor={vendor} createAndDownloadPdf={createAndDownloadPdf} reportId={reportId} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SynupTemplate;