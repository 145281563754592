import '../src/Styles/style.css';
import '../src/Styles/responsive.css';

import {
  BrowserRouter as Router
} from "react-router-dom";

import AppRouter from './AppRouter';
import Toasts from './Components/Toasts';
import { useAppContext } from './Hooks/useAppContext';
import { useEffect, useState } from 'react';

function App() {

  const { darkMode, loadAppConfigurations }: any = useAppContext();
  const [appLoaded, setAppLoaded] = useState(false);

  useEffect(() => {
    setAppLoaded(true);
  }, []);

  useEffect(() => {
    if (appLoaded) {
      loadAppConfigurations();
    }
  }, [appLoaded]);

  useEffect(() => {
    document.body.setAttribute("dark-theme", darkMode);
  }, [darkMode]);

  return (
    <>
      <Router>
        {/* routes */}
        <AppRouter />
        {/* toasts */}
        <Toasts />
      </Router>
    </>
  );

}

export default App;
