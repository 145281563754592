import { useNavigate } from "react-router-dom";
import ScanResults from "../../ScanResults";
import Step1 from "./Step1";

const SmallBusinessInfoTemplate = (props) => {

    const navigate = useNavigate();
    const { vendor, activeTab, formFields, handleStep2, createAndDownloadPdf, reportId } = props;

    return (
        <>

            <div className="performace-bg">
                <div className='main_wrapper '>
                    <div className="performance-local">
                        <div className="performace-logo">
                            <img onClick={(e) => navigate('/home')}  src={vendor?.logo}  alt="" />
                        </div>

                        {
                            (activeTab === 1 || activeTab === 2) ? <>
                                <Step1 vendor={vendor} formFields={formFields} onSubmit={handleStep2} />
                            </> : null
                        }

                        {
                            (activeTab === 3) ? <>
                                <ScanResults vendor={vendor} createAndDownloadPdf={createAndDownloadPdf} reportId={reportId} />
                            </> : null
                        }

                    
                    </div>
                </div>
            </div>

            <div className="copyright-footer-sec">
                <p>Copyright @ {(new Date())?.getFullYear()}, {vendor?.name}. All Rights Reserved.</p>
            </div>

        </>
    );
}

export default SmallBusinessInfoTemplate;