import { useEffect, useState } from "react";
import { haveValue } from "../../Utils/helpers";

const WebsiteLogo = (props) => {

    const { website, service, websiteLink } = props;

    const [name, setName] = useState(website?.name);
    const [slug, setSlug] = useState(website?.slug);
    const [link, setLink] = useState(website?.siteUrl);

    useEffect(() => {
        let name = website?.name;
        let slug = website?.slug;
        let link = website?.siteUrl;

        let serviceDetails = website?.serviceDetails;

        if (
            haveValue(serviceDetails) &&
            haveValue(serviceDetails[service])  
        ) {
            if (haveValue(serviceDetails[service]?.name)) {
                name = serviceDetails[service]?.name;
            }
            if (haveValue(serviceDetails[service]?.slug)) {
                slug = serviceDetails[service]?.slug;
            }
            if (haveValue(serviceDetails[service]?.siteUrl)) {
                link = serviceDetails[service]?.siteUrl;
            }
        }

        if (haveValue(websiteLink)) {
            link = websiteLink;
        }

        setName(name);
        setSlug(slug);
        setLink(link);

    }, [websiteLink, website]);

    return (
        <>
            <a href={link} target="_blank" title={name} className={`website-icon ${service}`}>
                <div className="img-box" style={{ backgroundImage: "url('/images/vendor/" + slug + ".png')" }}></div>
                <span>{name}</span>
            </a>
        </>
    );
}
export default WebsiteLogo;