import React, { useEffect, useState } from "react";
import { Col, Row, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import * as parseGooglePlace from "parse-google-place";
import Autocomplete from "react-google-autocomplete";
import {
  getBusinessCategories,
  getCountries,
  getStates,
  matchBusinessCategories,
} from "../../../../Services/public-service";
import {
  handleFormikChange,
  handleFormikSubmit,
  haveValue,
  parseSocialHandle,
  underScoredToNormal,
} from "../../../../Utils/helpers";
import {
  hideLoadingToast,
  showLoadingToast,
} from "../../../../Redux/slices/toastSlice";
import FormFieldSelectSearch from "../../../FormFields/FormFieldSelectSearch";
import FormFieldSelect from "../../../FormFields/FormFieldSelect";
import FormField from "../../../FormFields/FormField";
import { GOOGLE_MAP_API_KEY } from "../../../../Utils/constants";

const Step1 = (props) => {
  const configurations = useSelector((state: any) => state.configurations);
  const vendor = configurations?.vendor;

  const { formFields } = props;

  const [countries, setCountries]: any = useState([]);
  const [states, setStates]: any = useState([]);
  const [defaultBusinessCats, setDefaultBusinessCats]: any = useState([]);

  const [showCategory, setShowCategory]: any = useState(false);
  const [showContactInfoModal, setShowContactInfoModal]: any = useState(false);

  const handleModalClose = () => setShowContactInfoModal(false);
  const handleModalShow = () => setShowContactInfoModal(true);

  const [allValues, setAllValues] = useState({});

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    businessName: yup.string().required("Company Name is required"),
    businessCategory: yup.string(),
    country: yup.string(),
    state: yup.string(),
    city: yup.string(),
    businessAddress: yup.string().required("Company Address is required"),
    zipcode: yup.string().required("Zip is required"),
    businessPhone: yup.string().required("Company Phone Number is required"),
    facebookHandle: yup.string(),
    instagramHandle: yup.string(),
    linkedInHandle: yup.string(),
    twitterHandle: yup.string(),
  });

  const validationSchema2 = yup.object({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Enter a valid Email Address")
      .required("Email is required"),
  });

  const form = useFormik({
    initialValues: {
      businessName: "",
      website: "",
      businessCategory: "",
      country: "",
      state: "",
      city: "",
      businessAddress: "",
      zipcode: "",
      businessPhone: "",
      facebookHandle: "",
      instagramHandle: "",
      linkedInHandle: "",
      twitterHandle: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setAllValues(values);
      handleModalShow();
    },
  });

  const form2 = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: validationSchema2,
    onSubmit: (values) => {
      props.onSubmit({ ...values, ...allValues });
    },
  });

  const loadCountries = () => {
    getCountries()
      .then((res: any) => {
        let countriesData: any = [];
        for (let country of res.data) {
          countriesData.push({
            _id: country?.isoCode,
            name: country?.name,
          });
        }
        setCountries(countriesData);
      })
      .catch((error) => {});
  };

  const loadStates = (countryCode) => {
    getStates({
      countryCode,
    })
      .then((res: any) => {
        let statesData: any = [];
        for (let state of res.data) {
          statesData.push({
            _id: state.isoCode,
            name: state.name,
          });
        }
        setStates(statesData);
      })
      .catch((error) => {});
  };

  const handleCountryChange = (countryCode: any) => {
    loadStates(countryCode);
    handleFormikChange(countryCode, form, "country");
  };

  useEffect(() => {
    loadCountries();
  }, []);

  const onPlaceSelect = (place, ref) => {
    console.log(place);
    let address = parseGooglePlace(place);

    if (!haveValue(address.address)) {
      if (haveValue(place?.vicinity)) {
        address.address = place?.vicinity;
        address.address = address.address.split(",");
        address.address.pop();
        address.address = address.address.join(", ");
      }
    }

    handleFormikChange(place?.formatted_phone_number, form, "businessPhone");

    handleFormikChange(address?.address, form, "businessAddress");
    handleFormikChange(address?.city, form, "city");
    handleFormikChange(address?.stateShort, form, "state");
    handleFormikChange(address?.countryShort, form, "country");

    handleFormikChange(address?.zipCode, form, "zipcode");

    if (haveValue(address?.countryShort)) {
      loadStates(address?.countryShort);
    }

    setTimeout((e) => {
      let input: any = document.querySelector("#businessNameField input");
      if (input != null) {
        input.value = place?.name;
        handleFormikChange(place?.name, form, "businessName");
      }
    }, 500);

    if (haveValue(place?.website)) {
      handleFormikChange(place?.website, form, "website");
    }

    if (haveValue(place.types)) {
      let businessTypes: any = [];

      if (place.types.length > 0) {
        for (let businessType of place.types) {
          businessTypes.push(underScoredToNormal(businessType));
        }
      }

      if (businessTypes.length > 0) {
        matchBusinessCategories({
          items: businessTypes,
        })
          .then((res: any) => {
            let categories: any = [];
            let data = res?.data;
            let matchedItem: any = null;
            if (data.length > 0) {
              for (let item of data) {
                categories.push({
                  value: item?.name,
                  label: item?.name,
                });
                if (data.length === 1) {
                  matchedItem = item;
                }
              }
            }

            setDefaultBusinessCats(categories);
            setTimeout((e) => {
              let businessType = "";
              if (matchedItem !== null) {
                businessType = matchedItem.name;
              }
              handleFormikChange(
                underScoredToNormal(businessType),
                form,
                "businessCategory"
              );
            }, 500);
          })
          .catch((err: any) => {});
      }
    }
  };

  const loadBusinessCategories = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    getBusinessCategories({
      q: inputValue,
    })
      .then((res: any) => {
        let categories: any = [];
        let data = res?.data;
        if (data.length > 0) {
          for (let item of data) {
            categories.push({
              value: item?.name,
              label: item?.name,
            });
          }
        }
        callback(categories);
      })
      .catch((err: any) => {
        callback([]);
      });
  };

  if (!showCategory) {
    console.log("form.errors", form.errors);
    if (Boolean(form.errors.zipcode)) {
      setShowCategory(true);
    }
    if (
      Boolean(form.errors.businessCategory) &&
      form.touched.businessCategory
    ) {
      setShowCategory(true);
    }
  }

  const handleAddressChange = (e) => {
    handleFormikChange(e, form);
    const address = e?.target?.value;
    if (haveValue(address)) {
      const addressDataD = address.split(",");
      const addressData = addressDataD
        .filter((item) => item.trim() !== "") // Remove empty values
        .map((item) => item.trim()); // Trim the remaining values
      console.log(addressData.length);
      console.log(addressData);
      switch (addressData.length) {
        case 2:
          handleFormikChange(addressData[1], form, "city");
          break;
        case 3:
          handleFormikChange(addressData[1], form, "city");
          handleFormikChange(addressData[2], form, "state");
          break;
        case 4:
          handleFormikChange(addressData[1], form, "city");
          handleFormikChange(addressData[2], form, "state");
          handleFormikChange(addressData[3], form, "country");
          break;
      }
    }
  };

  return (
    <>
      <Modal
        className="performance-modal"
        show={showContactInfoModal}
        onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Where do we send your results?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scan-now">
          <Form
            className="performance-form"
            onSubmit={(e) => handleFormikSubmit(e, form2)}>
            <div className="form-fields-body">
              <Row>
                <Col md={12}>
                  <FormField
                    id="name"
                    name="name"
                    icon="fa fa-user-o"
                    value={form2.values.name}
                    onChange={(e) => handleFormikChange(e, form2)}
                    showError={form2.touched.name}
                    errorText={form2.errors.name}
                    placeholder={"Enter Name"}
                  />
                </Col>
                <Col md={12}>
                  <FormField
                    id="email"
                    name="email"
                    icon="fa fa-envelope-o"
                    fieldType="email"
                    value={form2.values.email}
                    onChange={(e) => handleFormikChange(e, form2)}
                    showError={form2.touched.email}
                    errorText={form2.errors.email}
                    placeholder={"Enter Email"}
                  />
                </Col>
              </Row>
            </div>
            <button className="scan-btn" type="submit">
              Show me the Results!
            </button>
          </Form>
        </Modal.Body>
      </Modal>

      <div className="performance-banner-img">
        <img src="/images/performance-banner-bg.png"></img>
      </div>

      <div className="performance-middle-sec">
        <h1>People Are Calling- Is Your Business Registered?</h1>
        <h2>Verify Your Business Listing On: </h2>

        <div className="company-brands">
          <div className="brands">
            <img src="/images/alexa-logo.png"></img>
            <p>Amazon Alexa</p>
          </div>
          <div className="brands">
            <img src="/images/apple-logo.png"></img>
            <p>Amazon Alexa</p>
          </div>
          <div className="brands">
            <img src="/images/google-voice.png"></img>
            <p>Amazon Alexa</p>
          </div>
          <div className="brands">
            <img src="/images/android.png"></img>
            <p>Amazon Alexa</p>
          </div>
        </div>
        <h4 className="mx-767">
          Check to see if your business is registered on all 5 voice search
          platforms and start getting more phone calls!
        </h4>
        <div className="form-sec w-100">
          <Form
            className="performance-form"
            onSubmit={(e) => handleFormikSubmit(e, form)}>
            <div className="row">
              <div className="col-12 col-xxl-12">
                <div className="theme-forminput " id="businessNameField">
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa fa-user" aria-hidden="true"></i>
                    </span>
                    <Autocomplete
                      apiKey={vendor.googleMapApi}
                      onPlaceSelected={(place, ref) => {
                        onPlaceSelect(place, ref);
                      }}
                      onChange={(e) => {
                        const tEl: any =
                          document.querySelector(".pac-target-input");
                        if (tEl != null) {
                          handleFormikChange(tEl.value, form, "businessName");
                        }
                      }}
                      options={{
                        types: [],
                        fields: [
                          "address_components",
                          "formatted_phone_number",
                          "name",
                          "vicinity",
                          "website",
                          "type",
                        ],
                      }}
                      className="form-control"
                      // placeholder='Company Name'
                      defaultValue={form.values.businessName}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-xxl-12">
                <FormField
                  id="businessAddress"
                  name="businessAddress"
                  icon="fa fa-map"
                  label={"Company Address"}
                  value={form.values.businessAddress}
                  onChange={(e) => handleAddressChange(e)}
                  showError={form.touched.businessAddress}
                  errorText={form.errors.businessAddress}
                  placeholder={"Company Address"}
                />
              </div>
              <div className="col-12 col-xxl-12">
                <Row>
                  <Col md="12" className="">
                    <FormField
                      id="businessPhone"
                      name="businessPhone"
                      icon="fa fa-phone"
                      label={"Company Phone Number"}
                      value={form.values.businessPhone}
                      onChange={(e) => handleFormikChange(e, form)}
                      showError={form.touched.businessPhone}
                      errorText={form.errors.businessPhone}
                      placeholder={"Company Phone Number"}
                    />
                  </Col>
                </Row>
                {showCategory ? (
                  <>
                    {/* <div className="col-12 col-xxl-12">
                                        <FormFieldSelectSearch
                                            id="businessCategory"
                                            name="businessCategory"
                                            icon="fa fa-building"
                                            label={"Business Category"}
                                            value={form.values.businessCategory}
                                            onChange={(e) => handleFormikChange(e, form, "businessCategory")}
                                            showError={form.touched.businessCategory}
                                            errorText={form.errors.businessCategory}
                                            placeholder={"Enter Business Category"}
                                            loadOptions={loadBusinessCategories}
                                            defaultOptions={defaultBusinessCats}
                                        />
                                    </div> */}
                    <Row>
                      <Col md={12}>
                        <FormField
                          id="zipcode"
                          name="zipcode"
                          icon="fa fa-location-arrow"
                          label={"Zipcode"}
                          value={form.values.zipcode}
                          onChange={(e) => handleFormikChange(e, form)}
                          showError={form.touched.zipcode}
                          errorText={form.errors.zipcode}
                          placeholder={"Enter Zipcode"}
                        />
                      </Col>
                    </Row>
                  </>
                ) : null}

                <div className="d-none">
                  <Row>
                    <Col md={12}>
                      <FormField
                        id="zipcode"
                        name="zipcode"
                        icon="fa fa-location-arrow"
                        label={"Zipcode"}
                        value={form.values.zipcode}
                        onChange={(e) => handleFormikChange(e, form)}
                        showError={form.touched.zipcode}
                        errorText={form.errors.zipcode}
                        placeholder={"Enter Zipcode"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <FormFieldSelect
                        id="country"
                        name="country"
                        icon="fa fa-globe"
                        label={"Country"}
                        value={form.values.country}
                        onChange={(e) => handleCountryChange(e?.target?.value)}
                        showError={form.touched.country}
                        errorText={form.errors.country}
                        placeholder={"Select Country"}
                        options={countries}
                      />
                    </Col>
                    <Col md={4}>
                      <FormFieldSelect
                        id="state"
                        name="state"
                        icon="fa fa-map-marker"
                        label={"State"}
                        value={form.values.state}
                        onChange={(e) => handleFormikChange(e, form)}
                        showError={form.touched.state}
                        errorText={form.errors.state}
                        placeholder={"Select State"}
                        options={states}
                      />
                    </Col>
                    <Col md={4}>
                      <FormField
                        id="city"
                        name="city"
                        icon="fa fa-map-pin"
                        label={"City"}
                        value={form.values.city}
                        onChange={(e) => handleFormikChange(e, form)}
                        showError={form.touched.city}
                        errorText={form.errors.city}
                        placeholder={"Enter City"}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="scan-now">
              <button className="scan-btn" type="submit">
                SCAN NOW
              </button>
            </div>
          </Form>
        </div>
        <div className="performace-footer-sec">
          <h3>
            <img className="arrow-icon" src="/images/arrow.png"></img>Your
            Business is Missing From These Sites:
          </h3>
          <div className="sites-sec">
            <div className="sites-logos">
              <img src="/images/Logos-list.png"></img>
            </div>
          </div>
          <h5>Over 40 million people use smart speaker devices.</h5>
          <h6>
            Virtually every smartphone today features voice search capabilities,
            seamlessly integrating with Amazon Alexa and other leading
            voice-activated devices.
          </h6>
          <h6>
            58% of consumers are using voice search technology everyday - can
            your business be found?
          </h6>
          <p>
            58% of consumers are using voice search technology everyday - can
            your business be found?
          </p>
        </div>
      </div>
    </>
  );
};

export default Step1;
